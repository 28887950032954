.section-subtitle {
  font-size: 14px;
}

.w-lg-75{
  width: 75%;
  margin: auto;
  @include media-breakpoint-down(sm){
    width: 100% !important;
  }
}