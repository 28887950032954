.multiselect__tags {
  .multiselect__tag {
    /*background: $primary!important;*/
    background: rgba($secondary,.5);
    &-icon{
      &:hover{
        background: rgba($secondary,.5);
      }
    }
  }
}

.multiselect__content {
  .multiselect__element {
    .multiselect__option {
      &--highlight{
        background: rgba($primary,.3);
      }
      &--selected{
        background: rgba($primary,.7);
        color: #fff;
      }
    }
  }
}