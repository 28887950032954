// 
// tables.scss
//

//Table centered (Custom)
.table-centered {
    th, td {
        vertical-align: middle !important;
    }
}


// Custom table components (Custom)
.table {
    color: #4a4a4a;
    background-color: transparent;

    th,
    td,
    thead th {
        border-color: #f1f1f1;
    }
    tbody tr {
        &:hover {
            background-color: #e2e2e2!important;
            //cursor: pointer;
        }

        .child{
            ul {
                list-style: none;
                .dtr-title{
                    display: none;
                }
            }
        }
    }
    &.table-striped {
        tbody tr:nth-of-type(odd) {
            background-color: #f1f1f1;
        }
    }
    .table-user {
        img {
            height: 30px;
            width: 30px;
        }
    }
    .action-icon {
        color: $gray-600;
        font-size: 1.2rem;
        display: inline-block;
        padding: 0 3px;

        &:hover {
            color: $gray-700;
        }
    }
}
