.member-page {
  .profile-user-box{
    .avatar-container{
      position: relative;
      .responsible-badge{
        position: absolute;
        top: -10px;
        right: 0;
        font-size: 23px;
        color: white;
        border: 3px solid white;
        border-radius: 50%;
        padding: 0px 7px;
        background: navajowhite;
      }
    }
  }
  .member-responsibles-wrapper {
    margin-top: 1rem;
  }
}